import { generateItemKeyIndex } from '@/views/knowledge/index/knowledge-task/generate-data.js';
import { generateFileData } from '@/views/knowledge/index/knowledge-task/generate-data.js';

export const resourceTypeValMap = {
  TEACHING: 1,
  UPLOAD_FILE: 3,
  KNOWLEDGE: 4
};

export function generateResourceItem(itemData) {
  const { type, id, name, recommendMins, skillArr } = itemData;
  const obj = {
    type
  };
  switch (type) {
    case resourceTypeValMap.UPLOAD_FILE:
      Object.assign(obj, {
        id,
        ...generateFileData(false, itemData)
      });
      break;
    case resourceTypeValMap.KNOWLEDGE:
      Object.assign(obj, {
        id,
        name,
        recommendMins,
        skillArr
      });
      break;
  }
  return obj;
}

export function generateSubTaskItem(isCreate, data = {}) {
  isCreate = Boolean(isCreate);
  const lv = 2;
  const { has, title, material_section_type_list } = data;
  let teachingArr = [];
  let knowledgeArr = [];
  const obj = {
    key: `${lv}-${generateItemKeyIndex()}`,
    lv,
    has,
    readOnly: Number(has) === 1
  };
  if (!isCreate && Array.isArray(material_section_type_list) && material_section_type_list[0]) {
    const { type_source_list } = material_section_type_list[0];
    const resourceArr = type_source_list;
    teachingArr = resourceArr.filter(({ data_type }) => Number(data_type) === resourceTypeValMap.UPLOAD_FILE).map(item => {
      const { data_type, source_id, source_info } = item;
      return generateResourceItem({
        id: source_id,
        ...(source_info || {}),
        type: Number(data_type)
      });
    });
    knowledgeArr = resourceArr.filter(({ data_type }) => Number(data_type) === resourceTypeValMap.KNOWLEDGE).map(item => {
      const { data_type, advice_time, source_id, base_name, source_info } = item;
      return generateResourceItem({
        type: Number(data_type),
        id: source_id,
        name: base_name,
        recommendMins: Number(advice_time) || 0,
        skillArr: Array.isArray(source_info) ? source_info : []
      });
    });
  }
  Object.assign(obj, isCreate ? {
    title: '子任务',
    teachingArr,
    knowledgeArr
  } : {
    title,
    teachingArr,
    knowledgeArr
  });
  return obj;
}

export function generateTaskItem(isCreate, data = {}) {
  isCreate = Boolean(isCreate);
  const lv = 1;
  const { title, section_list, has, sort } = data;
  const obj = {
    key: `${lv}-${generateItemKeyIndex()}`,
    lv,
    has
  };
  if (Number(has) === 0) {
    obj.sort = Number(sort);
  }
  Object.assign(obj, isCreate ? {
    title: '任务',
    section_list: [
      generateSubTaskItem('isCreate')
    ]
  } : {
    title: String(title),
    section_list: section_list.map(item => generateSubTaskItem(false, item))
  });
  return obj;
}