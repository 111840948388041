<template>
  <div>
    <NGrid :x-gap="isPage ? 15 : 0" :col="24">
      <NGi :span="6">
        <LabelList
          ref="labelListRef"
          label-list-title="标签列表"
          :label-type="resourceEnum.KNOWLEDGE_POINTS"
          show-no-label
          @list-update="handlePageChange(1);"

          :label-content-height="labelContentHeight"
        />
      </NGi>
      <NGi :span="18">
        <NCard size="medium" :bordered="false">
          <SearchForm ref="searchFormRef" @list-update="handlePageChange(1);" />
          <div v-if="isPage" style="margin-bottom: 15px;">
            <NButton
              v-if="ownUrlPermission('knowledge/delete')"
              type="error"
              :disabled="selectedIdsArr.length === 0"
              @click="toDel('multiple')"
            >批量删除</NButton>
            <NButton
              v-if="ownUrlPermission('knowledge/create')"
              style="float: right; margin-left: 15px;"
              type="primary"
              @click="handleAdd"
            >新增知识点</NButton>
            <!--
            <NButton
              v-if="ownUrlPermission('knowledge/distribute')"
              style="float: right;"
              @click="toDistribute"
            >分发</NButton>
            -->
          </div>
          <NAlert
            v-show="selectedIdsArr.length > 0"
            style="margin-bottom: 10px;"
            type="info"
          >
            已选择 <span class="theme-color">{{ selectedIdsArr.length }}</span> 项数据
          </NAlert>
          <NDataTable
            :columns="columns"
            :data="tableData"
            :row-key="row => row.id"
            remote
            :pagination="pagination"
            @update:page="handlePageChange"
            @update:page-size="handlePageSizeChange"
            v-model:checked-row-keys="selectedIdsArr"

            :max-height="tableContentHeight"
          />
        </NCard>
      </NGi>
    </NGrid>

    <Distribute
      ref="distributeRef"
      distribute-title="分发"
      :label-type="resourceEnum.KNOWLEDGE_POINTS"
      :distribute-fn="distributeKnowledge"
      @distribute-success="updateList"
    />
    <KnowledgeEditModal
      ref="knowledgeEditModalRef"
      @item-change="handleItemChange"
    />
    <PageLoading :loading="loading" />
  </div>
</template>

<script setup>
  import { ref, reactive, h, onMounted } from 'vue';
  import { useRouter } from 'vue-router';
  import { NTime, NSpace, NButton, NPopconfirm } from 'naive-ui';

  import PageLoading from '@/components/PageLoading/index.vue';
  import LabelList from '@/components/LabelList/LabelList.vue';
  import SearchForm from './components/SearchForm.vue';
  import Distribute from '@/components/Distribute/Distribute.vue';
  import KnowledgeEditModal from './components/KnowledgeEditModal.vue';
  
  import { resStatusEnum } from '@/enumerators/http.js';
  import { resourceEnum } from '@/enumerators/resource-types-map.js';
  import ownUrlPermission from '@/utils/own-url-permission.js';

  import { reqKnowledgeList, delKnowledge, distributeKnowledge, copyKnowledge } from '@/api/knowledge.js';
  
  const props = defineProps({
    isPage: {
      type: Boolean,
      default: false
    },
    filterIds: {
      type: Array,
      default: () => []
    },

    labelContentHeight: {
      type: String,
      default: 'auto'
    },
    tableContentHeight: {
      type: String,
      default: 'auto'
    }
  });

  const { SUCCESS } = resStatusEnum;
  const router = useRouter();
  const loading = ref(false);
  const labelListRef = ref(null);
  const searchFormRef = ref(null);

  const columns = [
    { type: 'selection' },
    { title: '标题', key: 'title' },
    { title: '建议时长（min）', key: 'advice_time' },
    { title: '创建者', key: 'admin_username' },
    {
      title: '更新日期',
      width: 190,
      render: row => h(
        NTime,
        {
          time: (Number(row.update_time) || 0) * 1000
        }
      )
    },
    /*
    {
      title: '分发院校数',
      width: 100,
      render: ({ distribution_school_num }) => {
        const num = Number(distribution_school_num) || 0;
        return h(
          'span',
          num > 0 ? null : { style: 'color: #ccc;' },
          num > 0 ? num : '未分发'
        )
      }
    },
    */
    {
      title: '使用情况',
      width: 90,
      render: ({ change }) => {
        change = Number(change);
        let propObj = null;
        let txt = '已使用';
        if (change === 0) {
          propObj = { style: 'color: #ccc;' };
          txt = '未使用'
        }
        return h('span', propObj, txt);
      }
    }
  ];
  if (props.isPage) {
    columns.push({
      title: '操作',
      width: 140,
      render: row => {
        if (Number(row.upload_status) === 1) {
          return h('span', { style: 'color: #ccc' }, '正在生成语音...');
        } else {
          return h(
            NSpace,
            null,
            {
              default: () => {
                const tempArr = [];
                if (ownUrlPermission('knowledge/task-detail')) {
                  tempArr.push(h(NButton, { text: true, type: 'primary', tag: 'a', target: '_blank', href: `/full-page/knowledge-preview?id=${ row.id }` }, { default: () => '查看' }));
                }
                if (ownUrlPermission('knowledge/update')) {
                  tempArr.push(h(NButton, { text: true, type: 'primary', onClick: () => handleEditKnowledge(row) }, { default: () => '编辑信息' }));
                }
                if (ownUrlPermission('knowledge/task-detail') && Number(row.change) === 0) {
                  tempArr.push(h(NButton, { text: true, type: 'primary', onClick: () => handleSetKnowledgeTask(row) }, { default: () => '任务设置' }));
                }
                if (ownUrlPermission('knowledge/copy')) {
                  tempArr.push(
                    h(
                      NPopconfirm,
                      { onPositiveClick: () => confirmCopy(row) },
                      {
                        default: () => h('div', null, [h('span', null, '你确定要复制此内容吗？'), h('br'), h('span', null, '内容涉及MP3、MP4文件需重新上传。')]),
                        trigger: () => h(NButton, { text: true, type: 'primary' }, { default: () => '复制' })
                      }
                    )
                  );
                }
                if (ownUrlPermission('knowledge/delete')) {
                  tempArr.push(h(NButton, { text: true, type: 'error', onClick: () => toDel('single', row) }, { default: () => '删除' }));
                }
                return tempArr;
              }
            }
          );
        }
      }
    });
  }

  const tableData = ref([]);
  const pagination = reactive({
    page: 1,
    itemCount: 0,
    pageSize: 10,
    pageSizes: [10, 20, 30],
    showSizePicker: true
  });
  
  const updateItemCount = (count) => {
    pagination.itemCount = Number(count) || 0;
  };
  const handlePageChange = (page) => {
    pagination.page = page;
    updateList();
  };
  const handlePageSizeChange = (pageSize) => {
    pagination.pageSize = pageSize;
    handlePageChange(1);
  };
  const getReqParams = () => {
    const params = {
      ...searchFormRef.value.getSearchParams(),
      page: pagination.page,
      page_size: pagination.pageSize
    };
    const ids = labelListRef.value.getSelectedIds();
    if (ids.includes('0')) { // “未设置标签”
      params['Knowledge[labels_num]'] = 0;
    } else {
      const labelsStr = ids.join(',');
      labelsStr && (params['Knowledge[labels]'] = labelsStr);
    }
    excludeTeachingIds.length > 0 && (params['Knowledge[ids]'] = excludeTeachingIds.join(','));
    return params;
  };
  const updateList = () => {
    loading.value = true;
    reqKnowledgeList(getReqParams()).then(({ code, data }) => {
      if (code === SUCCESS) {
        tableData.value = data.list || [];
        updateItemCount(data.total);

        updateIdTeachingMap(data.list);
      }
    }).finally(() => {
      loading.value = false;
    });
  }

  const knowledgeEditModalRef = ref(null);
  const handleAdd = () => {
    knowledgeEditModalRef.value.openCreate();
  };
  function handleEditKnowledge({ id }) {
    knowledgeEditModalRef.value.openEdit(id)
  }
  const handleSetKnowledgeTask = ({ id }) => {
    router.push(`knowledge-task?id=${id}`);
  };
  function handleItemChange() {
    updateList();
    labelListRef.value.updateLabelList();
  }

  function confirmCopy({ id, title }) {
    loading.value = true;
    copyKnowledge({
      'Knowledge[id]': id
    }).then(({ code, data }) => {
      if (code === SUCCESS) {
        window.$message.success(`“${title}”复制成功`);
        // updateList();
        if (data && data.id) {
          handleSetKnowledgeTask({ id: data.id });
        }
      } else {
        loading.value = false;
      }
    }).catch(() => {
      loading.value = false;
    });
  }

  const selectedIdsArr = ref([]);
  const clearSelectedIds = () => {
    selectedIdsArr.value.splice(0);
  };
  const toDel = (type, singleRow) => {
    const dialog = window.$dialog;
    const message = window.$message;
    switch (type) {
      case 'single':
        const { id, title } = singleRow;
        dialog.warning({
          title: '删除确认',
          content: `确定删除知识点“${title}”？`,
          positiveText: '确定',
          negativeText: '取消',
          onPositiveClick: () => {
            loading.value = true;
            delKnowledge({ 'Knowledge[ids]': [id] }).then(res => {
              loading.value = false;
              if (res.code === SUCCESS) {
                message.success('删除成功');
                handlePageChange(1);
                labelListRef.value.updateLabelList();
                clearSelectedIds();
              }
            }).catch(err => {
              loading.value = false;
            });
          },
          onNegativeClick: () => {}
        });
        break;
      case 'multiple':
        const ids = selectedIdsArr.value;
        dialog.warning({
          title: '删除确认',
          content: `确定删除${ids.length}个知识点？`,
          positiveText: '确定',
          negativeText: '取消',
          onPositiveClick: () => {
            loading.value = true;
            delKnowledge({ 'Knowledge[ids]': ids }).then(res => {
              loading.value = false;
              if (res.code === SUCCESS) {
                message.success('批量删除成功');
                handlePageChange(1);
                labelListRef.value.updateLabelList();
                clearSelectedIds();
              }
            }).catch(err => {
              loading.value = false;
            });
          },
          onNegativeClick: () => {}
        })
        break;
    }
  }

  onMounted(() => {
    if (props.isPage) {
      excludeTeachingIds.splice(0);
      updateList();
    }
  });

  const distributeRef = ref(null);
  const toDistribute = () => {
    distributeRef.value.openModal();
  };

  const idTeachingMap = {};
  const updateIdTeachingMap = resData => {
    if (Array.isArray(resData)) {
      resData.forEach(({ id, title, advice_time, source_info }) => {
        idTeachingMap[id] = {
          id,
          title,
          recommendMins: Number(advice_time) || 0,
          skillArr: Array.isArray(source_info) ? source_info : []
        };
      });
    }
  };

  const getSelectedTeachings = () => {
    const tempList = [];
    selectedIdsArr.value.map(id => {
      if (idTeachingMap[id]) {
        tempList.push(idTeachingMap[id]);
      }
    });
    return tempList;
  };
  const excludeTeachingIds = [];
  const initData = ({ filterIds = [] } = {}) => {
    clearSelectedIds();
    excludeTeachingIds.splice(0);
    excludeTeachingIds.push(...filterIds);
    handlePageChange(1);
  };

  defineExpose({
    getSelectedTeachings,
    initData
  });
</script>

<style lang="less" scoped>
  @import "~@/styles/variables.less";
  .theme-color {
    color: @theme-color;
  }
</style>