export const courseTypeValMap = {
  COURSE: 0,
  TEACHING_SYSTEM: 1
};

export const courseTypeValNameMap = {
  0: '课程',
  1: '教学系统'
};

export const courseTypeValChapterNameMap = {
  0: '章',
  1: '任务'
};

export const courseTypeValSectionNameMap = {
  0: '节',
  1: '子任务'
};