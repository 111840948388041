import request from '@/utils/request.js';
import { reqMethodEnum } from '@/enumerators/http.js'
const { GET, POST } = reqMethodEnum;

// 系统首页
export function getHomeIndex(data) {
  return request({
    url: '/backend/web/home/index',
    method: GET,
    data
  });
}

// 延长登录有效期
export function PostHomeIndex(data) {
  return request({
    url: '/backend/web/home/index',
    method: POST,
    data
  });
}

// 系统设置模块---------------
// 账号管理列表
export function AssignmentIndex(data) {
  return request({
    url: '/backend/web/admin/assignment/index',
    method: GET,
    params:data
  });
}

// 账号新增
export function AssignmentCreate(data) {
  return request({
    url: '/backend/web/admin/assignment/create',
    method: POST,
    data
  });
}

// 账号编辑
export function AssignmentUpdate(data) {
  return request({
    url: '/backend/web/admin/assignment/update',
    method: POST,
    data
  });
}

// 账号状态改变
export function AssignmentUpdateStatus(data) {
  return request({
    url: '/backend/web/admin/assignment/update-status',
    method: POST,
    data
  });
}

// 账号详情
export function GETAssignmentDelete(data) {
  return request({
    url: '/backend/web/admin/assignment/update?',
    method: GET,
    params:data
  });
}

// 账号删除
export function AssignmentDelete(data) {
  return request({
    url: '/backend/web/admin/assignment/delete',
    method: POST,
    data
  });
}

// 获取角色列表
export function SystemPowerRoles(data) {
  return request({
    url: '/backend/web/system-power/roles',
    method: GET,
    params:data
  });
}

// 账号密码重置
export function PostAssignmentReset(data) {
  return request({
    url: '/backend/web/admin/assignment/reset',
    method: POST,
    data
  });
}