<template>
  <div>
    <NButton type="primary" text style="margin: 10px 0;" @click="handleClickAddLabel">点击添加</NButton>
    <div>
      <div
        v-for="item in selectedLabels"
        :key="item.id"
        style="display: inline-block; line-height: 28px; margin: 0 5px 5px 0; padding: 0 5px; border-radius: 3px; font-size: 13px;"
        :style="{ border: `1px solid ${ themeSettings.appThemeColor }`, color: themeSettings.appThemeColor }"
      >
        {{ item.title }}（{{ item.file_num }}）
        <NIcon
          size="20"
          color="#999"
          style="vertical-align: top; margin-top: 4px; cursor: pointer"
          @click="removeLabel(item)"
        >
          <CloseOutline />
        </NIcon>
      </div>
    </div>

    <AddLabelModal
      ref="addLabelModalRef"
      v-bind="$attrs"
      @list-update="handleLabelChange"
    />
  </div>
</template>

<script setup>
  import { ref } from 'vue';
  import { CloseOutline } from '@vicons/ionicons5';

  import AddLabelModal from '@/components/AddLabelModal/AddLabelModal.vue';

  import themeSettings from '@/settings/theme-settings.js';

  const emit = defineEmits(['list-update']);

const selectedLabels = ref([]);

  const addLabelModalRef = ref(null);
  const handleClickAddLabel = () => {
    addLabelModalRef.value.openModal(
      selectedLabels.value.map(item => item.id)
    );
  };
  const handleLabelChange = args => {
    const { selectedLabelsArr } = args;
    selectedLabels.value = selectedLabelsArr;
    emit('list-update', selectedLabelsArr);
  };
  const removeLabel = labelItem => {
    selectedLabels.value.splice(selectedLabels.value.indexOf(labelItem), 1);
    emit('list-update', selectedLabels.value);
  };

  const setSelectedLabels = (labels) => {
    /*
    [
      {
        id: xxx,
        title: xxx,
        file_num: xxx
      }
    ]
    */
    selectedLabels.value = labels;
  };
  defineExpose({
    setSelectedLabels
  });
</script>