<template>
  <div id="course-build-main">
  <NSteps :current="currentStep" :status="currentStatus" style="margin: 5px 0 30px;">
    <NStep v-if="!fixContent" title="教学系统架构" /> 
    <!-- <NStep title="课程架构" /> -->
    <NStep title="学习设置" />
  </NSteps>
  <Step1
    v-if="!fixContent"
    v-show="currentStep === maxStep - 2"
    ref="step1Ref"
    @id-label-update="idLabel = $event"
    @id-jlabel-update="JidLabel = $event"
    @id-klabel-update="KidLabel = $event"
    @id-slabel-update="SidLabel = $event"
    @step-next="nextStep"
    @modal-close="closeModal"
    v-bind="$attrs"

    :materialTypeVal="materialTypeValMap.TASK"
    :courseTypeVal="courseTypeValMap.TEACHING_SYSTEM"
  />
  <Step2
    ref="step2Ref"
    v-if="currentStep === maxStep - 1"
    @step-pre="preStep"
  />
  </div>
</template>

<script setup>
  import { ref, defineExpose, computed, nextTick, defineEmits } from 'vue';
  
  import { NSteps, NStep } from 'naive-ui';

  import Step1 from '@/views/course/build/components/Step1.vue';
  import Step2 from './components/Step2.vue';

  import { materialTypeValMap } from '@/enums/material-type.js';
  import { courseTypeValMap } from '@/enums/course-type.js';

  import { PostHomeIndex } from '@/api/admin-home.js';

  const emit = defineEmits(["list-update"]);

  const props = defineProps({
    distributeTitle: {
      type: String,
      default: ''
    },
    fixContent: {
      type: Boolean,
      default: false
    }
  });

  const maxStep = computed(() => {
    return props.fixContent ? 2 : 3;
  });

  const step1Ref = ref(null);
  const step2Ref = ref(null);

  const modalShow = ref(true);
  const openModal = () => {
    modalShow.value = true;

    if (props.fixContent) {
      nextTick(() => {
        // step2Ref.value.initTableData();
      });
    } else {
      step1Ref.value && step1Ref.value.handleClear();
    }
  };

  const closeModal = () => {
    modalShow.value = false;
  };

  const currentStep = ref(1);
  const currentStatus = ref('process');
  const nextStep = () => {
    const tempMaxStep = maxStep.value;
    if (currentStep.value < tempMaxStep) {
      currentStep.value += 1;
      nextTick(() => {
        step2Ref.value.initData();
      });
    }
    // if (tempMaxStep > 2 && currentStep.value === 2) {
    //   step2Ref.value.initTableData();
    // }
  };
  const preStep = () => {
    if (currentStep.value > 1) {
      currentStep.value -= 1;
    }
  };

  const idLabel = ref({});
  const JidLabel = ref({});
  const KidLabel = ref({});
  const SidLabel = ref({});
  
  const idSchool = ref({});

  // 在当前页面时 每半小时请求接口 刷新验签 防止登录过期
  setInterval(function(){
    PreventExpiration()
  },1800000)
  function PreventExpiration(){
    PostHomeIndex().then(res=>{
    }).catch(err=>{})
  }
  PreventExpiration()

  defineExpose({
    openModal
  });
</script>
<style lang="less" scoped>
  #course-build-main{
    background: #fff;
    padding: 20px;
  }
  .n-step-content-header__title{
    font-weight: 700;
  }
  .n-card-header__main{
    font-weight: 700 !important;
  }
</style>