<template>
  <div class="alone-possess">
    <!-- <NCard size="small" :border="false" :segmented="segmented" title=""> -->
      <!-- 
      <NForm
        :label-width="100"
        :inline="true"
        require-mark-placement="left"
        label-align="right"
        label-placement="left"
        :model="formValues"
        :rules="formRules"
      >
        <NFormItem
          label="课程名称："
          path="Course[base_name]"
          show-require-mark
          class="build-inputs"
        >
          <NInput
            style="width: 250px"
            maxlength="50"
            placeholder="请输入课程名称："
            show-count
            clearable
            v-model:value="formValues['Course[base_name]']"
          />
        </NFormItem>

        <NFormItem
          path="Course[image_urls]"
          label="课程封面："
          show-require-mark
          class="build-inputs uploader-container"
          style="width: 500px"
        >

          <UPheadPortrait
            text="限上传PNG、JPG格式图片 最佳设计宽高比 200:130，大小不超过2M"
            @imagedata="imagedata"
            size="2"
            removeUrl="/backend/web/upload/delete"
            :imaData="[]"
            v-if="!isOne"
            @error="errorimg"
          />
             
          <UPheadPortrait
            text="限上传PNG、JPG格式图片 最佳设计宽高比 200:130，大小不超过2M"
            @imagedata="imagedata"
            size="2"
            removeUrl="/backend/web/upload/delete"
            :imaData="formValues['Course[image_urls]']"
            @error="errorimg"
            v-else
          />
        </NFormItem>

        <NFormItem label="简介：" path="Course[introduce]" class="build-inputs">
          <NInput
            style="width: 300px"
            type="textarea"
            maxlength="60"
            show-count
            placeholder="请输入课程简介"
            clearable
            v-model:value="formValues['Course[introduce]']"
          />
        </NFormItem>
      </NForm>

      <template v-if="showModal"> </template>

      <NFormItem>
        <NButton type="primary" @click="addAdmin" class="add-teaching-material"
          >导入教材</NButton
        >
      </NFormItem>
      -->

      <!-- 导入教材 -->
      <template>
        <n-modal
          class="custom-card"
          v-model:show="showModal"
          preset="card"
          :style="bodyStyle"
          :title="`导入${materialShowName}`"
          :bordered="false"
          :segmented="segmented"
        >
          <template #header-extra></template>
          <NForm inline label-placement="left">
            <NFormItem label="">
              <NInput
                style="width: 320px;"
                maxlength="50"
                show-count
                clearable
                v-model:value="formValue.title"
                :placeholder="`请输入${materialShowName}名称或${materialShowName}ID`"
                @update:value="ChangeTitle"
              />
            </NFormItem>
            <NFormItem>
              <NSpace>
                <NButton type="primary" @click="handleSearch">搜索</NButton>
                <NButton @click="handleClear">清除</NButton>
              </NSpace>
            </NFormItem>
          </NForm>
          <NAlert
            v-show="Pselect.length > 0"
            style="margin-bottom: 10px"
            type="info"
          >
            已选择
            <span :style="{ color: '#300000' }">{{ Pselect.length }}</span>
            项数据
          </NAlert>
          <n-data-table
            ref="table"
            :columns="columns"
            :data="schoolOptions"
            :pagination="pagination"
            :row-key="(row) => row.id"
            :row-value="(row) => row.title"
            :default-checked-row-keys="formValues['Course[material]']"
            remote
            @update:page-size="handlePageChange"
            @update:page="handlePageSizeChange"
            @update:checked-row-keys="handleCheck"
          />
          <template #footer>
            <NButton type="primary" @click="ImportDetermine" class="school-btn"
              >确定</NButton
            >
          </template>
        </n-modal>
      </template>
      <!-- 导入教材结束 -->
    <!-- </NCard> -->

    <div>
      <NButton
        class="add-teaching-material"
        type="primary"
        @click="addAdmin" 
      >导入{{ materialShowName }}</NButton>
    </div>

    <!-- 拖拽 -->
    <!-- 导入的数据 -->
    <p class="build-text1">提示：可拖动{{ chapterShowName }}排序</p>
    <VueDraggableNext
      class="dragArea list-group w-full"
      @change.stop.prevent
      @end.stop.prevent="onEnd"
    >
      <div
        class="build-list"
        v-for="item in formValues['Course[list]']"
        :key="item"
      >
        <!-- 手动新增的数据 -->
        <div class="build-list-headers" v-if="Number(item.has) === 0">
          <div class="build-list-header">
            <!-- <span class="build-list-heade-title manual-tag">手动添加</span> -->
            <NTag class="import-way-tag" type="success">手动添加</NTag>
            <span class="build-text2">{{ chapterShowName }}：</span>
            <n-input
              style="width: 300px;"
              v-model:value="item.title"
              maxlength="20"
              show-count
              type="text"
              :placeholder="`请输入${chapterShowName}名称`"
            />
            <n-button
              type="error"
              size="small"
              ghost
              class="build-delete-btn"
              @click="DeleteEvent(0, item.id, item.title, item.time)"
              >删除</n-button
            >
          </div>
          <VueDraggableNext
            style="margin-left: 92px;"
            @change.stop.prevent
            @end.stop.prevent="handleSectionDrapEnd(item.section_list, $event)"
          >
            <div
              class="build-list-item build-list-item1"
              v-for="item1 in item.section_list"
              :key="item1"
            >
              <span class="build-text2">{{ sectionShowName }}：</span
              ><n-input
                style="width: 300px; text-indent: 0;"
                v-model:value="item1.title"
                maxlength="20"
                show-count
                type="text"
                :placeholder="`请输入${sectionShowName}名称`"
              />
              <n-button
                v-show="item.section_list.length > 1"
                type="error"
                size="small"
                ghost
                class="build-delete-btn"
                @click="DeleteEvent(1, item1.id, item1.title, item1.time)"
                >删除</n-button
              >
            </div>
          </VueDraggableNext>
          <NButton
            type="success"
            ghost
            @click="addSection(item.id, item.time)"
            class="build-add-chapter-1"
            ><Add />新增{{ sectionShowName }}</NButton
          >
        </div>
        <!-- 导入的数据 -->
        <div class="build-list-headers" v-if="Number(item.has) === 1">
          <div class="build-list-header">
            <!-- <span class="build-list-heade-title">教材导入</span> -->
            <NTag class="import-way-tag" type="primary">{{ materialShowName }}导入</NTag>
            <span class="build-text2">{{ materialShowName }}：</span>
            <span class="build-text3">{{ item.title }}</span>
            <span class="build-text2">创建人：</span>
            <span class="build-text3">{{ item.admin_username }}</span>
            <n-button
              type="error"
              size="small"
              ghost
              class="build-delete-btn"
              @click="DeleteEvent(0, item.id, item.title,undefined,999)"
              >删除</n-button
            >
          </div>
          <n-collapse>
            <n-collapse-item
              :title="`${chapterShowName}：${item1.title}`"
              :name="item1.id"
              class="build-list-cont"
              v-for="item1 in item.chapter"
              :key="item1.id"
            >
              <div
                class="build-list-item"
                v-for="item2 in item1.section_list"
                :key="item2.id"
              >
                {{ `${sectionShowName}：${item2.title}` }}
              </div>
            </n-collapse-item>
          </n-collapse>
        </div>
      </div>
    </VueDraggableNext>
    <!-- 拖拽结束 -->

    <!-- 新增章节按钮 -->
    <div style="text-align: center;">
      <NButton @click="addChapter" type="success" ghost class="build-add-chapter">
        <Add />
        新增{{ chapterShowName }}
      </NButton>
    </div>

    <div class="build-btns1" style="margin-top: 20px">
      <NButton size="large" @click="handleBack">保存并返回</NButton>
      <NButton size="large" type="primary" @click="nextStep">下一步</NButton>
    </div>

    <PageLoading :loading="loading" />
  </div>
</template>

<script setup>
import { ref, reactive, h, computed } from "vue";
import { useRouter, useRoute } from "vue-router";
import { NButton, NTag, useMessage, NTime, useDialog } from "naive-ui";
import { Add } from "@vicons/ionicons5";
import { VueDraggableNext } from "vue-draggable-next";

import UPheadPortrait from "@/components/UPheadPortrait/index.vue";

import PageLoading from "@/components/PageLoading/index.vue";

import { materialTypeValNameMap } from '@/enums/material-type.js';
import { courseTypeValNameMap, courseTypeValChapterNameMap, courseTypeValSectionNameMap } from '@/enums/course-type.js';

import {
  PostCourseUpdate,
  getMaterialList,
  GetCourseDetail,
  PostGetMaterialSource,
} from "@/api/course.js"; // 新增编辑提交 教材系统列表 课程详情 教材列表确定
import { resStatusEnum } from "@/enumerators/http.js";

const props = defineProps({
  materialTypeVal: {
    required: true,
    type: Number
  },
  courseTypeVal: {
    required: true,
    type: Number
  },
});

const courseShowName = computed(() => courseTypeValNameMap[props.courseTypeVal]);
const materialShowName = computed(() => materialTypeValNameMap[props.materialTypeVal]);
const chapterShowName = computed(() => courseTypeValChapterNameMap[props.courseTypeVal]);
const sectionShowName = computed(() => courseTypeValSectionNameMap[props.courseTypeVal]);

// 解决火狐浏览器 拖拽会产生新标签页的BUG
function drag(ev) {
  document.body.ondrop = function (event) {
    event.preventDefault();
    event.stopPropagation();
  };
}
drag()

const { SUCCESS } = resStatusEnum;
const message = useMessage();
const loading = ref(false);
const isOne = ref(false); // 数据是否初始化
const dialog = useDialog();

const route = useRoute();
const router = useRouter();
const teachingId = route.query.id;

const showModal = ref(false); // 添加教材系统弹窗

const temporaryList = ref([]); // 临时拖动数据

// 拖动后触发
const onEnd = (event) => {
  const dragItem = formValues.value['Course[list]'].splice(event.oldIndex, 1)[0];
  formValues.value['Course[list]'].splice(event.newIndex, 0, dragItem);
};
function handleSectionDrapEnd(sectionArr, { oldIndex, newIndex }) {
  const dragItem = sectionArr.splice(oldIndex, 1)[0];
  sectionArr.splice(newIndex, 0, dragItem);
}

const getDefaultFormValue = () => ({
  title: "",
  data_type: props.materialTypeVal,
  page: 1,
  page_size: 10,
});

const formValue = reactive(getDefaultFormValue());

// 监控标题输入
 function ChangeTitle(title) {
  pagination.page = 1;
  formValue.page = 1;
}

// 教材系统列表数据
function InitTeachingMaterial(reqData) {
  loading.value = true;
  getMaterialList(reqData)
    .then((res) => {
      loading.value = false;
      schoolOptions.value = res.data.list;
      // 总数据 分页
      updateItemCount(res.data.total);
    })
    .catch((err) => {});
}
InitTeachingMaterial(formValue);

// 导入教材系统事件
const addAdmin = () => {
  showModal.value = !showModal.value;
};

// 搜索
function handleSearch() {
  InitTeachingMaterial(formValue);
}

// 翻页 搜索参数集
const pagination = reactive({
  page: 1,
  itemCount: 0,
  pageSize: 10,
  pageSizes: [10, 20, 30],
  showSizePicker: true,
});
const updateItemCount = (count) => {
  pagination.itemCount = Number(count) || 0;
};
const handlePageChange = (pageSize) => {
  pagination.pageSize = pageSize;
  formValue.page_size = pageSize;
  InitTeachingMaterial(formValue);
};
const handlePageSizeChange = (page) => {
  pagination.page = page;
  formValue.page = page;
  InitTeachingMaterial(formValue);
};

const handleClear = () => {
  Object.assign(formValue, getDefaultFormValue());
  handleSearch();
};

const bodyStyle = {
  width: "800px",
};
const schoolOptions = ref([]); // 教材列表
const Pselect = ref([]); // 教材临时已选ID
const segmented = {
  content: "soft",
  footer: "soft",
};
// 教材选择ID事件
const handleCheck = (rowKeys, values) => {
  Pselect.value = rowKeys;
};

// 教材确定ID事件
function ImportDetermine(params) {
  if (Pselect.value.length === 0) {
    message.error(`请选择要导入的${materialShowName.value}`);
    return;
  }
  showModal.value = false;
  formValues.value["Course[material]"] = Pselect.value;
  loading.value = true;
  PostGetMaterialSource({
    material_ids: Pselect.value,
  })
    .then((res) => {
      let newMaterialArr = [];
      if (Array.isArray(res.data)) {
        const existsMaterialIDArr = formValues.value['Course[list]'].filter(({ has }) => Number(has) === 1).map(({ id }) => id);
        newMaterialArr = res.data.filter(({ id }) => !existsMaterialIDArr.includes(id));
      }
      formValues.value["Course[list]"] = formValues.value[
        "Course[list]"
      ].concat(newMaterialArr);
    })
    .catch((err) => {})
    .finally(() => {
      loading.value = false;
    });
}

const columns = [
  {
    type: "selection",
  },
  {
    title: `${materialShowName.value}ID`,
    key: "id",
  },
  {
    title: `${materialShowName.value}名称`,
    key: "title",
  },
  {
    title: "创建人",
    key: "admin_username",
  },
  {
    title: "更新日期",
    render: (row) =>
      h(NTime, {
        time: (Number(row.update_time) || 0) * 1000,
      }),
  },
];

// 父子组件传值 封面
const imagedata = (params) => {
  console.log('父子组件传值',params);
  if(params.length!=0&&params[0].url!=''){
    let data = {
      url:params[0].url,
      name:params[0].name
    }
    formValues.value["Course[image_urls]"]= [data]
    formValues.value["Course[image]"] = params[0].url;
    // console.log('父子组件传值99999999999',formValues.value);
  }else{
    formValues.value["Course[image_urls]"]= []
    formValues.value["Course[image]"] = ''
  }
};

// 编辑时原有文件列表
const formValues = ref({
  "Course[id]": "", //课程id
  "Course[base_name]": "", //课程名称
  // "Course[image_urls]": [], //组件所用的图片路径
  "Course[image]": "", //传给后端的图片路径
  "Course[introduce]": "", //简介
  "Course[material]": [], //导入的教材ID
  // "Course[delete_material]":[], //删除的教材ID
  "Course[list]": [], // 所有教材数据集
});

const formRules = {
  "Course[base_name]": {
    required: true,
    message: "必填",
    trigger: "blur",
  },
};

const SaveOrReturn = ref(0)   // 1保存返回 或 2提交  
// 返回事件
const handleBack = () => {
  SaveOrReturn.value = 1
  nextStepMethod()
};

const emit = defineEmits(["step-next", "id-label-update", "modal-close"]);

// 是否允许下一步
const isNextStep = ref(true)

// 下一步
const nextStep = () => {
  SaveOrReturn.value = 2
  nextStepMethod()
}

// 下一步
const nextStepMethod = () => {
  loading.value = true;
  // 延迟500毫秒 组件传值时间间隔
  if(isNextStep.value){
    // isNextStep.value = false
    setTimeout(function (params) {
      loading.value = false;
      if(formValues.value['Course[list]'].length==0){
        message.error(`请添加${chapterShowName.value}内容`);
        return  false
      }/* else if(formValues.value['Course[base_name]'] ==''){
        message.info("课程名称不能为空");
        return  false
      }else if(formValues.value['Course[image_urls]'].length==0){
        message.info("课程封面不能为空");
        return  false
      }else{ */

      const manualChapterArr = formValues.value['Course[list]'].filter(({ has }) => Number(has) === 0);
      if (manualChapterArr.find(({ title }) => !String(title).trim())) {
        message.error(`有名称为空的${chapterShowName.value}`);
        return;
      }
      if (manualChapterArr.find(({ section_list }) => {
        if (Array.isArray(section_list) && section_list.length > 0) {
          return section_list.find(({ title }) => !String(title).trim());
        } else {
          return true;
        }
      })) {
        message.error(`有名称为空的${sectionShowName.value}`);
        return;
      }

        DataSubmission(() => {
          if(SaveOrReturn.value==1){
            router.push(`/course/index/index?type=${props.courseTypeVal}`)
          }else{
            emit("step-next");
          }
        });
      /* } */
    },1000)
  }else{
    message.info("数据传输中,请稍后2秒点击");
    loading.value = false;
    // 间隔5秒后 自动回复下一步点击状态
    setTimeout(function(){
      isNextStep.value = true
    },1000)
  }
};

// 数据提交
function DataSubmission(fun) {
  // SaveOrReturn.value = 0
  loading.value = true;
  // 给数据添加sort
  formValues.value["Course[list]"].forEach((item, index) => {
    item.sort = index + 1;
  });
  PostCourseUpdate(formValues.value)
    .then((res) => {
      if (res.code === SUCCESS) {
        loading.value = false;
        message.success(res.msg);
        fun();
      }
    })
    .catch((err) => {})
    .finally(() => {
      loading.value = false;
    });
}

// 删除事件
const DeleteEvent = (Hierarchy, id, title, time, ispt) => {
  // Hierarchy  层级关系 0删除章节或者教材  1删除小节
  // id         要删除数据的ID
  // time       时间戳
  // ispt       999 是否平台
  // console.log("删除事件",Hierarchy,id,title)
  dialog.warning({
    title: "删除确认",
    content: `确定删除“${title}”？`,
    positiveText: "确定",
    negativeText: "取消",
    onPositiveClick: () => {
      loading.value = true;
      formValues.value["Course[list]"].forEach((item, index) => {
        // 旧数据删除
        // 手动添加 且层级是0
        if (time == undefined) {
          if (Hierarchy == 0 && item.id == id) {
            formValues.value["Course[list]"].remove(
              index,
              formValues.value["Course[list]"]
            );
          } else {
            if (item.section_list != undefined) {
              item.section_list.forEach((item1, index1) => {
                if (item1.id == id) {
                  item.section_list.remove(index1, item.section_list);
                  return;
                }
              });
            }
          }
          if(ispt==999){
          // 导入数据删除
            formValues.value["Course[material]"].forEach((item, index) => {
              formValues.value["Course[material]"].remove(
                index,
                formValues.value["Course[material]"]
              );
            });
          }
        } else {
          if (Hierarchy == 0 && item.time == time) {
            formValues.value["Course[list]"].remove(
              index,
              formValues.value["Course[list]"]
            );
          } else {
            if (item.section_list != undefined) {
              item.section_list.forEach((item1, index1) => {
                if (item1.time == time) {
                  item.section_list.remove(index1, item.section_list);
                  return;
                }
              });
            }
          }
        }

        // 新数据删除
      });
      loading.value = false;
    },
    onNegativeClick: () => {},
  });
};

// 数据初始化
GetCourseDetail({
  "Course[id]": teachingId,
  "Course[type]": "1",
})
  .then((res) => {
    loading.value = false;
    formValues.value["Course[material]"] = res.data.material_id_list; // 选择的教材ID集
    Pselect.value = res.data.material_id_list; // 选择的教材ID集
    formValues.value["Course[id]"] = res.data.id;
    formValues.value["Course[base_name]"] = res.data.base_name;
    formValues.value["Course[introduce]"] = res.data.introduce;
    const _list = res.data.list;
    if (Array.isArray(_list)) {
      _list.forEach(item => {
        if (Number(item.has) === 1) {
          item.chapter = item.chapter_list;
        }
      });
      formValues.value["Course[list]"] = _list;
    }
    if(res.data.image!=''){
      // formValues.value["Course[image_urls]"] = [{ name: "资源不存在", url: res.data.image, status: "finished" }] // 图标url数组
      formValues.value["Course[image]"] = res.data.image; // 图标url
      isOne.value = true;
    }else{
      // formValues.value["Course[image_urls]"] = [] // 图标url数组
      formValues.value["Course[image]"] = ''; // 图标url
    }
    
  })
  .catch((err) => {})
  .finally(() => {
    loading.value = false;
  });

const InitData = () => {};
// 新增章节事件
const addChapter = (params) => {
  let time = Date.parse(new Date());
  // formValues.value["Course[list]"]
  let data = {
    has: 0,
    id: "",
    title: `${chapterShowName.value}名称`,
    sort: "",
    time: time,
    section_list: [
      {
        id: "",
        title: `${sectionShowName.value}名称`,
        time: time,
        section_list: [],
      },
    ],
  };
  formValues.value["Course[list]"].push(data);
};

// 新增小节事件
const addSection = (id, istime) => {
  let time = Date.parse(new Date());
  let data = {
    id: "",
    title: `${sectionShowName.value}名称`,
    time: time,
    section_list: [],
  };
  // 如果istime存在的时候则用istime作为标识
  let is = istime == undefined ? id : istime;
  formValues.value["Course[list]"].forEach((item, index) => {
    if (is == item.id || is == item.time) {
      item.section_list.push(data);
    }
  });
};

// 数组删除下标
Array.prototype.remove = function (dx, arr) {
  if (isNaN(dx) || dx > arr.length) {
    return false;
  }
  for (var i = 0, n = 0; i < arr.length; i++) {
    if (arr[i] != arr[dx]) {
      arr[n++] = arr[i];
    }
  }
  arr.length -= 1;
};

const errorimgSrc = '../../../../../public/img/upload/CB8D4.png'
// 图片404处理
function errorimg(e) {
  e.target.src = errorimgSrc
}

</script>
<style scoped lang="less">
@import "~@/styles/variables.less"; 

.task-text1 {
  color: #999;
  text-indent: 120px;
  margin: 0;
}
.school-btn {
  width: 120px;
  float: right;
}

.school-text1 {
  margin-top: 6px;
  width: 380px;
}
.school-text1 .n-button {
  position: relative;
  top: 4px;
  left: 10px;
}
.list-styles {
  width: 200px;
  border: 1px solid red;
  border-radius: 5px;
  height: 30px;
  margin: 10px;
  margin: 0 auto;
  cursor: pointer;
}
.build-inputs .n-form-item-label {
  float: left;
}
.add-teaching-material {
  width: 120px;
}
.build-text1 {
  color: #ccc;
  margin-bottom: 0;
}
.build-list {
  border: 1px dashed #ccc;
  width: 100%;
  margin-top: 15px;
  box-sizing: border-box;
  padding: 20px;
  border-radius: 5px;
  cursor: pointer;

  &.sortable-chosen {
    border-color: @primary-color;
  }
}
.build-list-headers .build-text2 {
  color: #666;
}
.build-list-headers .build-text3 {
  color: #000;
  display: inline-block;
  margin-right: 20px;
}
.build-list-heade-title {
  display: inline-block;
  background: @theme-color;
  color: #fff;
  text-align: center;
  padding: 10px;
  border-radius: 6px;
  margin-right: 20px;

  &.manual-tag {
    background-color: @success-color
  }
}
.import-way-tag {
  margin-right: 25px;
}

.build-btns1 .n-button {
  width: 120px;
  margin-right: 15px;
}
.n-collapse {
  width: 90%;
  margin-top: 20px;
  padding-left: 120px;
}
.build-list-item {
  height: 36px;
  line-height: 36px;
  text-indent: 30px;
}
.build-list-item1 {
  /* position: relative; */
  // margin-left: 92px;
  margin-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px dashed #ddd;

  &.sortable-chosen {
    border-color: @primary-color;
  }
}
.build-add-chapter {
  width: 200px;
  margin-top: 20px;
}
.build-add-chapter-1 {
  width: 120px;
  margin-top: 20px;
  margin-left: 90px;
}
.build-add-chapter .n-button__content svg,
.build-add-chapter-1 .n-button__content svg {
  width: 20px;
}
.build-delete-btn {
  float: right;
  margin-top: 3px;
}
</style>
<!-- <style lang="less">
  #course-build-main .alone-possess #pictureUpload .n-upload-trigger.n-upload-trigger--image-card .n-upload-dragger{
  width: 177px !important;
  height:100px;
  position: relative;
  background-size: 100% 100% !important;
}
#course-build-main .alone-possess .title-up-text {
  left: 175px !important;
}
#course-build-main .n-upload-file-list .n-upload-file.n-upload-file--image-card-type{
  width: 177px !important;
}
#course-build-main .alone-possess .title-up-text{
  width: 144px !important;
}
</style> -->
<style lang="less" scoped>
  .uploader-container {
    :deep(.n-form-item-blank) {
      align-items: flex-start;
      height: 130px;
    }
    :deep(.n-upload-dragger), :deep(.n-upload-file-info__action), :deep(.n-upload-file-list .n-upload-file.n-upload-file--image-card-type) {
      width: 200px !important;
      height: 130px !important;
    }
    :deep(.n-upload-dragger) {
      background-position: center center !important;
      background-size: 60% !important;
      background-color: #FAFAFC !important;
    }
    :deep(.title-up-text) {
      left: 220px;
    }
    :deep(.n-upload-file-info__thumbnail img) {
      object-fit: contain !important;
    }
  }
</style>