import request from '@/utils/request.js';

import { reqMethodEnum } from '@/enumerators/http.js'
const { GET, POST } = reqMethodEnum;

export function reqKnowledgeList(data) {
  return request({
    url: '/backend/web/knowledge/index',
    method: GET,
    params: data
  });
}

export function createKnowledge(data) {
  return request({
    url: '/backend/web/knowledge/create',
    method: POST,
    data
  });
}

export function editKnowledge(data) {
  return request({
    url: '/backend/web/knowledge/update',
    method: POST,
    data
  });
}

export function reqKnowledgeDetail(data) {
  return request({
    url: '/backend/web/knowledge/detail',
    method: GET,
    params: data
  });
}

export function delKnowledge(data) {
  return request({
    url: '/backend/web/knowledge/delete',
    method: POST,
    data
  });
}

export function distributeKnowledge(data) {
  return request({
    url: '/backend/web/knowledge/distribute',
    method: POST,
    data
  });
}

export function copyKnowledge(data) {
  return request({
    url: '/backend/web/knowledge/copy',
    method: POST,
    data
  });
}

export function reqKnowledgeTaskDetail(data) {
  return request({
    url: '/backend/web/knowledge/task-detail',
    method: POST,
    data
  });
}

export function editKnowledgeTask(data) {
  return request({
    url: '/backend/web/knowledge/task-create',
    method: POST,
    data
  });
}