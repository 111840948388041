import { resourceTypeValMap } from '@/views/material/index/task-build/generate-data.js';

function generateReqSubTaskData({ title, teachingArr, knowledgeArr }, index) {
  const obj = {
    section_title: title,
    sort: index + 1,
    model_list: [
      {
        title: '任务内容',
        sort: 1,
        list: []
      }
    ]
  };
  const list = obj.model_list[0].list;
  if (teachingArr.length > 0) {
    teachingArr.forEach(({ id, name }) => {
      list.push({
        type: resourceTypeValMap.UPLOAD_FILE,
        ids: id,
        base_name: name
      });
    });
  }
  if (knowledgeArr.length > 0) {
    knowledgeArr.forEach(({ id, name }) => {
      list.push({
        type: resourceTypeValMap.KNOWLEDGE,
        ids: id,
        base_name: name
      });
    });
  }
  return obj;
}

function generateReqTaskData({ title, section_list, sort }) {
  const obj = {
    chapter_title: title,
    section_list: section_list.map((item, index) => generateReqSubTaskData(item, index)),
    sort
  };
  return obj;
}

export function generateReqData(courseID, taskArr) {
  const list = [];
  taskArr.forEach(item => {
    Number(item.has) === 0 && list.push(generateReqTaskData(item));
  });
  return {
    'Course[id]': courseID,
    'Course[type]': 1,
    'Course[list]': list
  }
}