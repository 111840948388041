import request from '@/utils/request.js';
import requestFile from '@/utils/request-file.js';
import { formDataUploadFile } from '@/utils/formdata-upload-file.js';
import { reqMethodEnum } from '@/enumerators/http.js'
const { GET, POST } = reqMethodEnum;

export function getMaterialList(data) {
  return request({
    url: '/backend/web/material/index',
    method: GET,
    params: data
  });
}

export function batDelMaterial(data) {
  return request({
    url: '/backend/web/material/delete',
    method: POST,
    data
  });
}

export function distributeMaterials(data) {
  return request({
    url: '/backend/web/material/distribute',
    method: POST,
    data
  });
}

export function createMaterialTitle(data) {
  return request({
    url: '/backend/web/material/create',
    method: POST,
    data
  });
}
export function editMaterialTitle(data) {
  return request({
    url: '/backend/web/material/update',
    method: POST,
    data
  });
}

export function getMaterialDetail(data) {
  return request({
    url: '/backend/web/material/detail',
    method: GET,
    params: data
  });
}

export function editMaterial(data) {
  return request({
    url: '/backend/web/material/update',
    method: POST,
    data
  });
}

export function importDir(data) {
  return formDataUploadFile(
    '/backend/web/material/import',
    data
  );
}

export function exportDir(data) {
  return requestFile({
    url: '/backend/web/material/export',
    method: POST,
    data,
    responseType: 'blob'
  });
}

export function copyMaterial(data) {
  return request({
    url: '/backend/web/material/copy',
    method: POST,
    data
  });
}