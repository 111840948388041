<template>
  <div>
    <NModal
      preset="dialog"
      :show-icon="false"
      :mask-closable="false"
      style="width: 550px;"
      :title="modalTitle"
      v-model:show="modalShow"
      positive-text="确定"
      negative-text="取消"
      @positive-click="handleSubmit"
      @after-leave="closeModal"
    >
      <NForm
        ref="formRef"
        :rules="formRules"
        :model="formValue"
        label-placement="left"
        label-width="100px"
        require-mark-placement="left"
        style="padding: 20px 15px 0 0;"
      >
        <NFormItem label="标题：" path="name">
          <NInput
            clearable
            maxlength="50"
            show-count
            placeholder="请输入知识点标题"
            v-model:value="formValue.name"
            @blur="handleNameBlur"
          />
        </NFormItem>
        <NFormItem label="核心知识：" path="introduce">
          <NInput
            clearable
            maxlength="2000"
            show-count
            type="textarea"
            placeholder="请输入本节核心知识点"
            v-model:value="formValue.introduce"
          />
        </NFormItem>
        <NFormItem label="标签：" path="labels">
          <ItemAddLabel
            ref="itemAddLabelRef"
            :label-type="resourceEnum.KNOWLEDGE_POINTS"
            @list-update="handleLabelChange"
          />
        </NFormItem>
      </NForm>
    </NModal>

    <PageLoading :loading="loading" />
  </div>
</template>

<script setup>
  import { ref, computed, reactive, nextTick } from 'vue';
  import { NModal, NForm, NFormItem, NInput, useMessage } from 'naive-ui';

  import PageLoading from '@/components/PageLoading/index.vue';
  import ItemAddLabel from '@/components/ItemAddLabel/ItemAddLabel.vue';

  import { resStatusEnum } from '@/enumerators/http.js';
  import { resourceEnum } from '@/enumerators/resource-types-map.js';

  import { createKnowledge, editKnowledge, reqKnowledgeDetail } from '@/api/knowledge.js';

  const message = useMessage();
  const { SUCCESS } = resStatusEnum;

  const emit = defineEmits(['item-change']);

  const loading = ref(false);
  const modalTitle = ref('新增知识点');
  const modalShow = ref(false);
  const knowledgeID = ref('');
  const isEdit = computed(() => knowledgeID.value !== '');

  function handleSubmit() {
    formRef.value.validate(errors => {
      if (!errors) {
        let reqFn = null;
        let successMsg = '';
        if (isEdit.value) {
          successMsg = '编辑成功';
          reqFn = editKnowledge;
        } else {
          successMsg = '新增成功';
          reqFn = createKnowledge;
        }
        loading.value = true;
        reqFn(getReqData()).then(({ code }) => {
          if (code === SUCCESS) {
            message.success(successMsg);
            emit('item-change');
            closeModal();
          }
        }).finally(() => {
          loading.value = false;
        });
      }
    });
    return false;
  }
  function resetFormValue() {
    Object.assign(formValue, getDefaultFormValue());
  }
  function closeModal() {
    resetFormValue();
    modalShow.value = false;
  }

  const formRef = ref(null);
  const formRules = {
    name: {
      required: true,
      message: '请输入知识点标题',
      trigger: 'blur'
    },
    introduce: {
      required: true,
      message: '请输入本节核心知识点',
      trigger: 'blur'
    },
    labels: {
      key: 'labels',
      type: 'array',
      required: true,
      message: '请选择标签',
      trigger: 'change'
    },
  };
  function getDefaultFormValue() {
    return {
      name: '',
      introduce: '',
      labels: []
    }
  }
  const formValue = reactive(getDefaultFormValue());
  function handleNameBlur() {
    formValue.name = formValue.name.trim();
  }

  const itemAddLabelRef = ref(null);
  const handleLabelChange = selectedLabelsArr => {
    formValue.labels.splice(0);
    formValue.labels.push(...selectedLabelsArr.map(({ id }) => id));
    
    formRef.value.validate(errors => {}, rule => {
      return rule.key === 'labels';
    });
  };

  function setFormValue({ title = '', introduce = '', label_list }) {
    let labels = [];
    if (Array.isArray(label_list)) {
      labels = label_list.map(({ label_id }) => label_id);
      itemAddLabelRef.value.setSelectedLabels(label_list.map(({
        label_id,
        title,
        file_num
      }) => ({
        id: label_id,
        title,
        file_num
      })));
    }
    Object.assign(formValue, {
      name: title,
      introduce: introduce,
      labels
    });
  }
  function getReqData() {
    const data = {
      'Knowledge[title]': formValue.name,
      'Knowledge[introduce]': formValue.introduce,
      'Knowledge[label]': formValue.labels
    };
    if (isEdit.value) {
      data['Knowledge[id]'] = knowledgeID.value;
    }
    return data;
  }

  defineExpose({
    openCreate() {
      modalTitle.value = '新增知识点';
      knowledgeID.value = '';
      modalShow.value = true;
    },
    openEdit(id) {
      modalTitle.value = '编辑知识点信息';
      knowledgeID.value = id;
      loading.value = true;
      reqKnowledgeDetail({
        'Knowledge[id]': id
      }).then(({ code, data }) => {
        if (code === SUCCESS) {
          modalShow.value = true;
          nextTick(() => {
            setFormValue(data);
          });
        }
      }).finally(() => {
        loading.value = false;
      });
    }
  });
</script>