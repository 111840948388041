import request from '@/utils/request.js';
import { reqMethodEnum } from '@/enumerators/http.js'
const { GET, POST } = reqMethodEnum;

// 广告管理 列表
export function GetBannerIndex(data) {
  return request({
    url: '/backend/web/banner/index',
    method: GET,
    params:data
  });
}

// 广告状态改变
export function PostBannerState(data) {
  return request({
    url: '/backend/web/banner/state',
    method: POST,
    data
  });
}

// 广告详情
export function GetBannerDetail(data) {
  return request({
    url: '/backend/web/banner/detail',
    method: GET,
    params:data
  });
}

// 广告删除
export function PostBannerDelete(data) {
  return request({
    url: '/backend/web/banner/delete',
    method: POST,
    data
  });
}

// 广告新增
export function PostBannerCreate(data) {
  return request({
    url: '/backend/web/banner/create',
    method: POST,
    data
  });
}

// 广告编辑 // 广告更改排序
export function PostBannerUpdate(data) {
  return request({
    url: '/backend/web/banner/update',
    method: POST,
    data
  });
}

// 广告更改排序
export function PostBannerSort(data) {
  return request({
    url: '/backend/web/banner/sort',
    method: POST,
    data
  });
}

// 上传临时文件
export function PostBannerTempUpload(data) {
  return request({
    url: '/backend/web/banner/temp-upload',
    method: POST,
    data
  });
}

