<template>
  <NModal
    v-model:show="modalShow"
    preset="dialog"
    title="添加标签"
    style="width: 900px;"
    :show-icon="false"
    positive-text="确认"
    negative-text="取消"
    @positive-click="handleSubmit"
    @after-leave="clearModal"
    :mask-closable="false"
  >
    <div style="position: relative; margin: 0 -28px; padding: 0 10px; border-bottom: 1px solid #f2f2f2;">
      <LabelList
        :preSelectedIdsArr="preSelectedIdsArr"
        :label-type="labelType"
        @list-update="handleSelect"
      />
      <div
        style="position: absolute; bottom: -42px; left: 28px;"
      >已选：<span :style="{ color: themeSettings.appThemeColor }">{{ selectedIdsArr.length }}</span> 个</div>
    </div>
  </NModal>
</template>

<script setup>
  import { ref, defineExpose } from 'vue';
  import { useMessage } from 'naive-ui';

  import LabelList from '@/components/LabelList/LabelList.vue';

  import themeSettings from '@/settings/theme-settings.js';

  const message = useMessage();

  const emit = defineEmits(['list-update']);
  defineProps({
    labelType: {
      required: true
    }
  });

  const preSelectedIdsArr = ref([]);

  const modalShow = ref(false);
  const openModal = idsArr => {
    modalShow.value = true;
    preSelectedIdsArr.value = idsArr;
  };
  const closeModal = () => {
    modalShow.value = false;
  }
  const clearModal = () => {
    selectedIdsArr.value.splice(0);
  };
  const handleSubmit = () => {
    if (selectedIdsArr.value.length > 0) {
      emit('list-update', {
        selectedIdsArr: [...selectedIdsArr.value],
        selectedLabelsArr
      });
      closeModal();
    } else {
      message.error('请选择标签');
    }
    return false;
  };

  const selectedIdsArr = ref([]);
  let selectedLabelsArr = [];
  const handleSelect = args => {
    selectedIdsArr.value = args.selectedIdsArr;
    selectedLabelsArr = args.selectedLabelsArr;
  };

  defineExpose({
    openModal
  });
</script>