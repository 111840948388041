import axios from 'axios';
import qs from 'qs';

import env from '@/settings/env.js';
import { getToken } from '@/utils/auth.js';
import { reqMethodEnum } from '@/enumerators/http.js'

const { apiUrlPrefix } = env;
const { POST } = reqMethodEnum;

const requestFile = axios.create({
  baseURL: apiUrlPrefix,
  timeout: 15000
});

requestFile.interceptors.request.use(
  config => {
    const token = getToken();
    token && (config.headers.Authorization = token);
    config.data = qs.stringify(config.data); // 转为formdata数据格式
    return config;
  },
  error => {
    console.log('Request error:', error);
    return Promise.reject(error);
  }
);

requestFile.interceptors.response.use(
  response => {
    // console.log(response);
    const res = response.data;

    return res;
  },
  error => {
    const message = window.$message;
    
    message.error(error instanceof Error ? error.message : error);
    console.log('Response error:', error);
    return Promise.reject(error);
  }
);

export default requestFile;