<template>
  <NForm
    inline
    label-placement="left"
  >
    <NFormItem label="搜索标题：">
      <NInput
        maxlength="50"
        show-count
        clearable
        v-model:value="formValue.title"
      />
    </NFormItem>
    <NFormItem>
      <NButton type="primary" @click="handleSearch">搜索</NButton>
      <NButton style="margin-left: 10px;" @click="handleClear">清除</NButton>
    </NFormItem>
  </NForm>
</template>

<script setup>
  import { reactive, defineEmits, defineExpose } from 'vue';
  import { NForm, NFormItem, NInput, NButton } from 'naive-ui';

  const getDefaultFormValue = () => ({
    title: ''
  });
  const formValue = reactive(getDefaultFormValue());

  const emit = defineEmits(['list-update']);
  const handleSearch = () => {
    emit('list-update');
  }
  const handleClear = () => {
    Object.assign(formValue, getDefaultFormValue());
    handleSearch();
  }

  defineExpose({
    getSearchParams: () => {
      const obj = {};
      formValue.title && (obj.title = formValue.title);
      return obj;
    }
  });
</script>