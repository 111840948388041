<template>
  <div style="margin: 10px 0;">
    <RenameDataItem
      v-for="(item, index) in list"
      :key="item[keyProp]"
      :readOnly="readOnly"
      :nameShow="item[nameProp]"
      @rename-confirm="item[nameProp] = $event"
    >
      <template v-if="!readOnly" #btns>
        <NIcon
          size="20"
          class="up-btn"
          @click="itemUp(index)"
        ><ArrowUpwardOutlined /></NIcon>
        <NIcon
          size="20"
          class="down-btn"
          @click="itemDown(index)"
        ><ArrowDownwardOutlined /></NIcon>
        <NIcon
          size="20"
          class="top-btn"
          @click="itemTop(index)"
        ><VerticalAlignTopOutlined /></NIcon>
        <NIcon
          size="20"
          class="bottom-btn"
          @click="itemBottom(index)"
        ><VerticalAlignBottomOutlined /></NIcon>
        <NIcon
          size="20"
          class="close-btn"
          @click="itemDel(index)"
        ><CloseOutlined /></NIcon>
      </template>
    </RenameDataItem>
  </div>
</template>

<script setup>
  import { NIcon } from 'naive-ui';
  import { CloseOutlined, VerticalAlignTopOutlined, VerticalAlignBottomOutlined, ArrowUpwardOutlined, ArrowDownwardOutlined } from '@vicons/material';

  import RenameDataItem from './RenameDataItem.vue';

  const emit = defineEmits(['item-del']);
  const props = defineProps({
    readOnly: {
      type: Boolean,
      default: false
    },
    nameProp: {
      type: String,
      default: 'name'
    },
    keyProp: {
      type: String,
      default: 'key'
    },
    list: {
      type: Array,
      default: () => []
    }
  });

  function itemUp(index) {
    if (index === 0) {
      return;
    }
    const listLink = props.list;
    const item = listLink.splice(index, 1)[0];
    listLink.splice(index - 1, 0, item);
  }
  function itemDown(index) {
    const listLink = props.list;
    if (index === listLink.length - 1) {
      return;
    }
    const item = listLink.splice(index, 1)[0];
    listLink.splice(index + 1, 0, item);
  }
  function itemTop(index) {
    if (index === 0) {
      return;
    }
    const listLink = props.list;
    const item = listLink.splice(index, 1)[0];
    listLink.splice(0, 0, item);
  }
  function itemBottom(index) {
    const listLink = props.list;
    if (index === listLink.length - 1) {
      return;
    }
    const item = listLink.splice(index, 1)[0];
    listLink.splice(listLink.length, 0, item);
  }
  function itemDel(index) {
    const item = props.list[index];
    emit('item-del', item.skillArr);
    props.list.splice(index, 1);
  }
</script>