<template>
  <div>
    <AddLocalFile
      ref="addLocalFileRef"
      v-show="false"
      triggerType="external"
      
      changeWithFileInfo

      :multiple="multiple"
      :maxNum="1"
      :accept="accept"
      :maxBytesSize="maxBytesSize"
      
      @file-list-change="handleUpFileChange"
    />
    <BeforeSubmitUpload
      ref="beforeSubmitUploadRef"
      :silentUpload="!uploadProgressShow"

      @all-file-success="handleAllFileSuccess"
      @upload-error="handleUploadError"
    />
  </div>
</template>

<script setup>
  import { ref, nextTick } from 'vue';
  import { useMessage } from 'naive-ui';

  import AddLocalFile from '@/components/AddLocalFile/AddLocalFile.vue';
  import BeforeSubmitUpload from '@/components/BeforeSubmitUpload/BeforeSubmitUpload.vue';

  const emit = defineEmits(['upload-start', 'upload-success', 'upload-error']);
  const props = defineProps({
    multiple: {
      type: Boolean,
      default: false
    },
    accept: {
      required: true,
      type: String
    },
    maxBytesSize: {
      type: Number,
      default: 0
    },
    uploadProgressShow: {
      type: Boolean,
      default: false
    }
  });

  const message = useMessage();

  const addLocalFileRef = ref(null);
  const beforeSubmitUploadRef = ref(null);

  function handleUpFileChange({ fileList }) {
    emit('upload-start');
    beforeSubmitUploadRef.value.startUpload([fileList]);
  }

  function handleAllFileSuccess(filesSet) {
    try {
      const { ext, fileType, fileUrl, name, size, videoId } = filesSet[0][0];
      emit('upload-success', { ext, fileType, fileUrl, name, size, videoId });
    } catch (e) {
      console.log(e);
      emit('upload-error');
    }
    addLocalFileRef.value.clearFileListCache();
  }
  function handleUploadError() {
    console.log('有文件上传失败，返回');
    message.error('文件上传失败，请稍后重试');

    addLocalFileRef.value.clearFileListCache();
    emit('upload-error');
  };

  defineExpose({
    openSelectFile() {
      nextTick(() => {
        addLocalFileRef.value.getUploadRef().openOpenFileDialog();
      });
    }
  });
</script>