<template>
  <div>
    <NGrid
      :x-gap="15"
      :cols="24"
      :style="{ visibility: stepInited ? 'visible' : 'hidden' }"
    >
      <NGi :span="8">
        <MaterialDir
          ref="dirRef"
          readOnly

          searchContentName="任务/子任务"
          :generateLv1ItemFn="() => generateTaskItem(true)"
          :generateLv2ItemFn="() => generateSubTaskItem(true)"

          :dirData="taskArr"
          @editing-section-change="handleSelectTask"
        />
      </NGi>
      <NGi :span="16">
        <div>
          <div v-if="selectedTask">
            <TaskContent
              isMaterialOrCourse="course"
              :addedKnowledgeIdArr="addedKnowledgeIdArr"
              :taskData="selectedTask"
              :skillArr="previewOtherData.all_skill"
              :idSkillObjMap="previewOtherData.idSkillObjMap"
            />
          </div>
          <div v-else style="padding: 90px 0; border: 1px solid #eee;">
            <NEmpty description="选择一个子任务开始">
              <template #icon>
                <NIcon>
                  <NotStartedOutlined />
                </NIcon>
              </template>
            </NEmpty>
          </div>
        </div>
      </NGi>
    </NGrid>
    <ExamSettings
      ref="examSettingsRef"
      style="margin: 15px 0;"
    />
    <NSpace size="small">
      <NButton size="large" style="width: 120px; margin-right: 15px;" @click="handleBack">返回</NButton>
      <NButton size="large" style="width: 120px;" @click="emit('step-pre');">上一步</NButton>
      <NButton
        size="large"
        style="width: 84px;"
        type="primary"
        ghost
        tag="a"
        target="_blank"
        href="/full-page/teaching-system-preview"
        @click="handlePreview"
      >预览</NButton>
      <NButton size="large" style="width: 120px;" type="primary" @click="handleDone">完成</NButton>
    </NSpace>

    <PageLoading :loading="loading" />
  </div>
</template>

<script setup>
  import { ref, reactive, computed, nextTick } from 'vue';
  import { useRoute, useRouter } from 'vue-router';
  import { NGrid, NGi, NEmpty, NIcon, NSpace, NButton, useMessage } from 'naive-ui';
  import { NotStartedOutlined } from '@vicons/material';

  import PageLoading from '@/components/PageLoading/index.vue';
  import MaterialDir from '@/views/material/index/build/components/MaterialDir.vue';
  import TaskContent from '@/views/material/index/task-build/components/TaskContent.vue';
  import ExamSettings from '@/views/course/build/components/ExamSettings.vue';

  import { resStatusEnum } from '@/enumerators/http.js';
  import { generateSubTaskItem, generateTaskItem } from '@/views/material/index/task-build/generate-data.js';
  import { generateReqData } from './req-data.js';
  import { courseTypeValMap } from '@/enums/course-type.js';

  import { GetCourseDetail, PostCourseUpdate } from '@/api/course.js';

  const emit = defineEmits(['step-pre']);

  const router = useRouter();
  const message = useMessage();
  const { SUCCESS } = resStatusEnum;
  const loading = ref(false);

  const stepInited = ref(false);
  const courseID = useRoute().query.id;
  const dirRef = ref(null);
  const taskArr = ref([]);
  const addedKnowledgeIdArr = computed(() => {
    const arr = [];
    taskArr.value.forEach(({ section_list }) => {
      section_list.forEach(({ knowledgeArr }) => {
        arr.push(...knowledgeArr.map(({ id }) => id))
      });
    });
    return arr;
  });

  const selectedTask = ref(null);
  function handleSelectTask({ chapterSectionType, data }) {
    selectedTask.value = chapterSectionType === 'section' ? data : null;
  }

  const examSettingsRef = ref(null);

  const previewOtherData = reactive({
    base_name: '',
    introduce: '',
    image: '',
    exam_list: '',
    all_skill: [],
    idSkillObjMap: {}
  });
  function handlePreview() {
    previewOtherData.exam_list = examSettingsRef.value.getData();
    window.localStorage.setItem('a-teaching-system-preview', JSON.stringify({
      ...previewOtherData,
      taskArr: taskArr.value
    }));
  }
  function handleDone() {
    examSettingsRef.value.validate().then(errMsg => {
      if (errMsg) {
        message.error(errMsg);
      } else {
        loading.value = true;
        PostCourseUpdate({
          ...generateReqData(courseID, taskArr.value),
          'Course[exam]': examSettingsRef.value.getData()
        }).then(({ code }) => {
          if (code === SUCCESS) {
            message.success('建设成功');
            handleBack();
          }
        }).finally(() => {
          loading.value = false;
        });
      }
    });
  }
  function handleBack() {
    router.push(`index?type=${courseTypeValMap.TEACHING_SYSTEM}`);
  }

  defineExpose({
    initData() {
      if (!courseID) {
        return;
      }
      loading.value = true;
      GetCourseDetail({
        'Course[id]': courseID,
        'Course[type]': 2
      }).then(({ code, data }) => {
        if (code === SUCCESS) {
          stepInited.value = true;
          taskArr.value.splice(0);
          const { list, base_name, exam_list, image, introduce, all_skill } = data;
          if (Array.isArray(list) && list.length > 0) {
            const formattedList = [];
            list.forEach(item => {
              switch (item.has) {
                case 0:
                  formattedList.push(item);
                  break;
                case 1: // 教材
                  Array.isArray(item.chapter_list) && item.chapter_list.forEach(materialTaskItem => {
                    materialTaskItem.has = item.has;
                    formattedList.push(materialTaskItem);
                  });
                  break;
              }
            });
            formattedList.forEach(({ has, section_list }) => {
              Array.isArray(section_list) && section_list.forEach(item => {
                item.has = has;
              });
            });
            taskArr.value = formattedList.map(item => generateTaskItem(false, item));
            examSettingsRef.value.initData(exam_list);
          }
          nextTick(() => {
            dirRef.value.updateDirDataItemTags();
            dirRef.value.expandAllChapters();
          });

          const idSkillObjMap = {};
          if (Array.isArray(all_skill)) {
            all_skill.forEach(item => {
              idSkillObjMap[item.id] = item;
            });
          }
          Object.assign(previewOtherData, {
            base_name,
            introduce,
            image,
            // exam_list,
            all_skill: Array.isArray(all_skill) ? all_skill : [],
            idSkillObjMap
          });
        }
      }).catch(err => {}).finally(() => {
        loading.value = false;
      });
    }
  });
</script>