<template>
  <div class="data-item">
    <div v-show="!renaming">
      <span>{{ nameShow }}</span>
      <span v-if="!readOnly" class="primary-color rename-btn" @click="handleClickRename">重命名</span>
    </div>
    <div v-show="renaming" v-clickoutside="confirmRename">
      <NInput
        style="width: 300px;"
        size="small"
        maxlength="20"
        show-count
        clearable
        v-model:value="newName"
        @keyup.enter="confirmRename"
      />
      <NIcon size="20" class="oper-rename-btn" @click="renaming = false">
        <ClearOutlined />
      </NIcon>
      <NIcon size="20" class="oper-rename-btn" @click="confirmRename">
        <CheckOutlined />
      </NIcon>
    </div>
    <div class="btns">
      <slot name="btns"></slot>
    </div>
  </div>
</template>

<script setup>
  import { ref } from 'vue';
  import { NInput, NIcon } from 'naive-ui';
  import { ClearOutlined, CheckOutlined } from '@vicons/material';

  const emit = defineEmits(['rename-confirm']);
  const props = defineProps({
    readOnly: {
      type: Boolean,
      default: false
    },
    nameShow: {
      type: String,
      default: ''
    }
  });

  const newName = ref('');
  const renaming = ref(false);
  function handleClickRename() {
    newName.value = props.nameShow;
    renaming.value = true;
  }
  function confirmRename() {
    if (renaming.value) {
      renaming.value = false;
      let newNameCopy = newName.value;
      if (newNameCopy && typeof newNameCopy === 'string' && newNameCopy.trim()) {
        newNameCopy = newNameCopy.trim();
      } else {
        newNameCopy = props.nameShow;
      }
      emit('rename-confirm', newNameCopy);
    }
  }
</script>

<style lang="less" scoped>
  @import "~@/styles/variables.less";

  .data-item {
    position: relative;
    line-height: 30px;
    margin: 0;
    padding: 0 30px 0 15px;
    transition: background-color .3s;
    cursor: default;

    &:hover {
      background-color: #f3f3f3;

      .rename-btn {
        opacity: 1;
      }

      .btns {
        opacity: 1;
      }
    }
  }

  .rename-btn {
    margin-left: 25px;
    font-size: 12px;
    opacity: 0;
    transition: opacity .3s;
    cursor: pointer;
  }

  .btns {
    position: absolute;
    right: 15px;
    top: 5px;
    color: #999;
    cursor: pointer;
    opacity: 0;
    transition: all .3s;

    :deep(.n-icon) {
      margin-left: 6px;
    }

    :deep(.up-btn), :deep(.down-btn) {
      &:hover {
        color: @success-color;
      }
    }

    
    :deep(.top-btn), :deep(.bottom-btn) {
      &:hover {
        color: @warning-color;
      }
    }

    :deep(.close-btn) {
      margin-left: 15px;
      
      &:hover {
        color: @error-color;
      }
    }
  }

  .oper-rename-btn {
    vertical-align: top;
    margin: 5px 0 0 5px;
    cursor: pointer;

    &:hover {
      color: @primary-color;
    }
  }
</style>